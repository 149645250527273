/* header{
    background-color: #000;
} */

.share-body{
    background-image: url(../imgs/share-bg.jpg);
    /* background-color: #f2f2f2; */
    /* background-color: #181a20; */
    min-height: 100vh;
    background-size: cover;
    padding-top: 15px;
    
}


.share-body header{
    background-color: transparent;
    padding: 20px 20px;
    background: linear-gradient(90deg, rgba(230,230,230,13%) 0%, rgba(175,175,175,13%) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid #982fff17;
    /* border: 1px solid rgba(255, 255, 255, 0.14); */
}

.balance-card{
    width: 100%;
    border-radius: 16px;
    padding: 20px;
    background-color: #00000066;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #982fff17;
}

.balance-card.balance-light{
    background-color: #50505066;
}

.balance-card h4{
    font-size: 18px;
    color: #acacac;
}

.balance-card .balance-text{
    color: #fff;
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    width: fit-content;
}

.balance-card .balance-text .currency{
    display: grid;
}

.balance-card .balance-text .currency-blocksky{
  font-size: 13px;
  font-weight: 600;
  background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.balance-card .balance-text .usdPrice{
    font-size: 13px;
    color: #acacac;
}

.balance-card .balance-text .balance{
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    line-height: 0;
}

.balance-card .withdraw-link{
    font-size: 13px;
    font-weight: 600;
    background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
    color: #fff;
    position:  absolute;
    top: 15px;
    right: 15px;
    padding: 5px 20px;
    border-radius: 30px;
    border: 0;
    outline: none !important;
}

.loading-data{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
    margin-right: auto;
}

.loading-data .loading-text{
    font-family: "Poppins",Tahoma,Arial;
    font-weight: 600;
    font-size: 20px;
}

.header-logo img{
    width: 130px;
}

.header-logo{
    font-family: "Poppins",Tahoma,Arial;
    font-weight: 600;
    font-size: 14px;
}

.header-logo a{
    display: flex;
    align-items: center;
    gap: 10px;
    background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header-items{
    display: flex;
    margin-left: auto;
    gap: 30px;
    align-items: center;
}

.header-items .header-menu{
    font-family: "DM Sans",Tahoma,Arial;
    font-size: 0.8rem;
    color: #fff !important;
    font-weight: 600;
    text-decoration: none !important;
    position: relative;
}

.header-items .header-menu.active::after{
    position: absolute;
    content: "";
    bottom: -5px;
    width: 166%;
    height: 2px;
    left: 50%;
    transform: translate(-50% , -50%);
    background: linear-gradient(90deg, rgba(136, 45, 191, 0) 0%, rgba(136, 45, 191, 1) 23%, rgba(55, 112, 236, 1) 77%, rgba(55, 112, 236, 0) 100%);
}


.disconnect-btn{
    text-align: center;
    border-radius: 15px;
    background: rgba(132,48,193,1);
    background: -moz-linear-gradient(-45deg, rgba(132,48,193,1) 0%, rgba(132,48,193,1) 31%, rgba(46,118,241,1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(132,48,193,1)), color-stop(31%, rgba(132,48,193,1)), color-stop(100%, rgba(46,118,241,1)));
    background: -webkit-linear-gradient(-45deg, rgba(132,48,193,1) 0%, rgba(132,48,193,1) 31%, rgba(46,118,241,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(132,48,193,1) 0%, rgba(132,48,193,1) 31%, rgba(46,118,241,1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(132,48,193,1) 0%, rgba(132,48,193,1) 31%, rgba(46,118,241,1) 100%);
    background: linear-gradient(135deg, rgba(132,48,193,1) 0%, rgba(132,48,193,1) 31%, rgba(46,118,241,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8430c1', endColorstr='#2e76f1', GradientType=1 );
    border: 0;
    color: #fff !important;
    font-family: "DM Sans",Tahoma,Arial;
    display: block;
    width: 100%;
    padding: 6px 20px;
    font-size: 0.75rem;
}

.disconnect-btn span{
    color: #fff;
}

.login-body{
   padding: 50px 0;
}

.login-card h3{
    text-align: center;
    color: #454545;
    font-family: "Poppins",Tahoma,Arial;
}

.wallets-choice{
    margin-top: 30px;
    display: grid;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;

}

.wallets-choice button{
    background-color: #fff;
    border: 0;
    border-radius: 10px;
    padding: 10px 0;
    font-family: "Poppins",Tahoma,Arial;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    outline: none !important;
    transition: 0.15s;
    box-shadow: 0 0 10px #302648;
}

.wallets-choice button img{
    width: 40px;
    border: 1px solid #e4e4e4;
    padding: 5px;
    border-radius: 10px;
}

.wallets-choice button:hover{
    transform: scale(1.04);
}


.card-balance{
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    font-family: "DM Sans",Tahoma,Arial;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 10px #d2d2d2;
}

.card-balance .balance-text small{
    font-weight: 800;
    font-size: 50%;
}

.card-balance .withdraw-link{
    position: absolute;
    display: inline-flex;
    top: 15px;
    right: 15px;
    width: fit-content;
    font-weight: 600;
    padding: 6px 15px;
    border-radius: 7px;
    font-size: 14px;
    border: 1px solid #858585;
    color: #454545 !important;
    transition: 0.15s;
    text-decoration: none !important;
}

.card-balance .withdraw-link:hover{
    background-color: #454545;
    color: #fff !important;
}

.card-selection{
    position: absolute;
    top: 15px;
    right: 15px;
    font-weight: 600;
    padding: 6px 15px;
    border-radius: 7px;
    font-size: 14px;
    background: linear-gradient(135deg, rgba(132, 48, 193, 1) 0%, rgba(132, 48, 193, 1) 31%, rgba(46, 118, 241, 1) 100%);
    color: #fff !important;
    border: 0;
    outline: none !important;
}

.card-selection option{
    color: #000;
}

.card-balance .balance-text{
    font-weight: 800;
    color: #454545;
    margin-bottom: 3px;
}

.card-balance span{
    color: #454545;
}

.card-balance h4{
    color: #858585;
}

.news-wrappper{
    display: flex;
    align-items: center;
    margin-top: 25px;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    background: rgb(150 150 150 / 13%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    /* border: 1px solid rgba(255, 255, 255, 0.14); */
    padding: 25px;
}

.news-wrappper .news-item{
    width: 23%;
    box-shadow: 0 0 10px #0b0b0b;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: 0.2s;
}

.news-wrappper .news-item:hover{
    transform: scale(1.06);
    box-shadow: 0 0 10px #12013b;
}

.news-wrappper .news-item .cover-image{
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.news-wrappper .news-item .news-body{
    background-color: #fff;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.news-wrappper .news-item .news-body h6{
    color: #000;
    font-family: "DM Sans",Tahoma,Arial;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    height: 68px;
    overflow: hidden;
}

.news-action{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
}

.news-wrappper .news-item .news-body .read-news{
    font-size: 14px;
    font-family: "DM Sans",Tahoma,Arial;
    border: 1px solid #3770ec;
    padding: 2px 10px;
    border-radius: 5px;
    color: #3770ec;
    transition: 0.15s;
}

.news-wrappper .news-item .news-body .read-news:hover{
    color: #fff;
    background-color: #3770ec;
}


.news-wrappper .news-item .news-body .get-link-btn{
    font-size: 14px;
    font-family: "DM Sans",Tahoma,Arial;
    border: 1px solid #4a60e2;
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
    transition: 0.15s;
    background-color: #4a60e2;
    outline: none !important;
}

.news-wrappper .news-item .news-body .news-date{
    font-size: 12px;
    color: #858585;
    font-family: "DM Sans",Tahoma,Arial;
}

.news-pagination{
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    align-items: end;
    margin-bottom: 25px;
    margin-top: 15px;
    list-style: none;
}

.news-pagination span{
    color: #454545;
}

.news-pagination a{
    /* border: 1px solid #fff; */
    color: #fff !important;
    border-radius: 50px;
    background-color: transparent;
    width: 30px;
    height: 30px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.15s;
    font-size: 14px;
}

.news-pagination a:hover{
    background-color: #000;
    color: #fff !important;
}

.news-pagination .active a{
    background: linear-gradient(135deg, rgba(132, 48, 193, 1) 0%, rgba(132, 48, 193, 1) 31%, rgba(46, 118, 241, 1) 100%);
    color: #fff !important;
    border: 1px solid #000;
}

.link-sharing{
    background-color: #3770ec;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    padding: 5px 15px;
    border: 2px solid #6791ed;
    width: 100%;
    display: flex;
    align-items: center;
}


.share-modal-header .modal-title{
    font-size: 18px;
    font-family: "DM Sans",Tahoma,Arial;
}

.modal-body{
    font-family: "DM Sans",Tahoma,Arial;
}

.copy-btn{
    color: #fff;
    border: 0;
    font-size: 18px;
    background: transparent;
    outline: none !important;
    margin-left: auto;
    transition: 0.15s;
}

.copy-btn:hover{
    transform: scale(1.1);
}

.copy-btn-dark{
    color: #000;
    border: 0;
    font-size: 14px;
    background: transparent;
    outline: none !important;
    margin-left: auto;
    transition: 0.15s;
}

.share-to img{
    width: 25px;
}

.white-card{
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    font-family: "DM Sans",Tahoma,Arial;
    /* box-shadow: 0 0 10px #d2d2d2; */
    height: 100%;
    /* min-height: 200px; */
}

.db-font{
    font-family: "DM Sans",Tahoma,Arial;
}

.update-nft-btn{
    width: 100%;
    padding: 15px 0;
    text-align: center;
    background-color: #202020;
    color: #fff;
    border-radius: 10px;
    border: 0;
    margin-top: 5px;
}

.white-card .input-group-button{
    background-color: #202020;
    color: #fff;
    border: 1px solid #202020;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.copy-aff-btn{
    background-color: transparent;
    border: 0;
    outline: none !important;
    padding: 0;
    color: #fff;
}

.user-affiliate{
    background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
    color: #fff;
    padding: 5px 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 54px;
    align-items: center;
}

.rules-btn{
    margin-left: auto;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: none !important;
}

.card-balance .max-btn{
    background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
    border: #fff;
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;
}

.blocksky-coin-text{
    background-color: #fff !important;
    color: #858585;
    border-left: 0 !important;
}

.card-balance input{
    border-right: 0 !important;
    outline:  none !important;
}

.confirm-withdraw-btn{
    background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
    width: 100%;
    border-radius: 10px;
    border: 0;
    padding: 10px 0;
    color: #fff;
}

.transaction-table{
    margin-top: 30px;
    padding: 15px;
    font-family: "DM Sans", Tahoma, Arial;
    background: rgb(150 150 150 / 13%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
}

.transaction-table table{
    border-radius: 7px;
    overflow: hidden;
}

.transaction-table table th{
    border: 0;
    text-align: center;
    background-color: #e4e4e4;
    color: #020202;
    font-weight: 300;
}

.transaction-table table td{
    font-size: 14px;
    text-align: center;
}

.flex-no-wrap{
    display: flex;
    flex-wrap: nowrap !important;
}

.table-filter{
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
    gap: 10px;
}

.table-filter input{
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    outline: none !important;
    width: fit-content;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 38px;
    border-left: 0;
}

.table-filter .input-group{
    width: fit-content !important;
}

.table-filter .input-group-text{
    border-right: 0;
    color: #ccc;
}

.table-filter .input-group select{
    border-left: 0;
    outline: none !important;
}

.search-btn{
    width: 40px;
    padding: 0;
    border: 0;
    outline: none !important;
    font-size: 14px;
    border-radius: 7px;
    background: linear-gradient(to right, #882dbf 0%, #3770ec 100%);
    color: #fff;
}

.reset-btn{
    width: 40px;
    padding: 0;
    border: 0;
    outline: none !important;
    font-size: 14px;
    border-radius: 7px;
    background-color: #fff;
    color: #202020;
    border: 1px solid;
    transition: 0.15s;
}

.reset-btn:hover{
    background-color: #202020;
    color: #fff;
}

.friends-btn{
    background-color: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
}

.nft-wrapper-check{
    display: flex;
    align-items: center;
    gap: 10px;
}

.nft-wrapper-check .nft-profile{
    width: 70px;
    height: 70px;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
}

.friends-list{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none !important;
}

.friends-list li{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 14px;
    padding: 10px;
    border-radius: 7px;
    background-color: #f1f1f1;
}

.friend-profile{
    width: 35px;
    height: 35px;
    border-radius: 7px;
    background-size: cover;
}

.friend-account{
    line-height: 16px;
}

.toast-container{
    z-index: 99999 !important;
}


@media only screen and (min-width: 600px) {
    .card-balance{
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .table-filter{
        flex-wrap: wrap;
        justify-content: start;
    }
    .header-items{
        width: 100%;
        margin: 0;
        margin-top: 10px;
        gap: 20px;
        justify-content: space-between;
    }
    .disconnect-btn{
        max-width: 125px;
    }
    .card-balance h4{
        font-size: 18px;
        margin-bottom: 0;
    }
    .news-wrappper .news-item{
        width: 47%;
    }
    .news-wrappper .news-item .news-body h6{
        font-size: 13px;
        height: 46px;
    }
    .white-card {
        margin: 15px 0;
        height: fit-content;
    }
}
/*  */